import React from "react";

function CusMail() {
  return (
    <div className="input-filters">
      <strong>Customer Email :</strong>
      <input className="multi" defaultValue="" type="text" name="o_cus_mail" />
    </div>
  );
}

export default CusMail;
