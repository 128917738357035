import React, { useState, useRef } from "react";
import { TextInput, PasswordInput, Tooltip, Button } from '@mantine/core';

import { useNavigate } from 'react-router-dom';

import { LogIn } from "../../features/dash/DashTopsSlice";
import { useDispatch } from "react-redux";
import axios from 'axios';

function SignInForm() {

  var dispatch = useDispatch();

  var navigate = useNavigate();
  var navigateRef = useRef(navigate);
  var [flag, setflag] = useState(false);

  var [email, setEmail] = useState('');
  var [password, setPassword] = useState('');

  var [opened, setOpened] = useState(false);
  var valid = password.trim().length >= 6;

  var handleSubmit = (event) => {

    event.preventDefault();

    var data = {email: email,password: password,};

    axios.post('https://server.shopex.io/login_form_submit.php', data, { withCredentials: true })
      .then(function (response) {
        console.log(response);
        if (response.data === 'welcome') {
          dispatch(LogIn());
          navigateRef.current('/Dashboard');
        } else if (response.data === 'invalid-credentials') {
          setflag(true);
        } else if (response === 'ppp') {
        } else if (response.data === 'Not') {
          alert("No Invitetion Sent to you");
        }
      })
      .catch(function (error) {
        console.log(error);
      })
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <TextInput
          mt="md"
          label="Your Eamil"
          placeholder="Your Email Address"
          onChange={(event) => setEmail(event.currentTarget.value)}
        />
        <Tooltip
          label={valid ? 'All good!' : 'Password must include at least 6 characters'}
          position="bottom-start"
          withArrow
          opened={opened}
          color={valid ? 'indigo' : undefined}
        >
          <PasswordInput
            label="Your password"
            required
            placeholder="Your password"
            onFocus={() => setOpened(true)}
            onBlur={() => setOpened(false)}
            mt="md"
            onChange={(event) => setPassword(event.currentTarget.value)}
          />
        </Tooltip>
        <Button type="submit" fullWidth mt='xl' px='xl' size="md" color="indigo">Submit</Button>
        {flag && <h3> Invalid Credentials </h3>}
      </form>
    </>
  );
}

export default SignInForm;
