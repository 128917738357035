import { ReactSession } from "react-client-session";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { get_recent_sales } from "../../features/dash/dashboard";

import EmailIcon from '@mui/icons-material/Email';
import Timeline from "@mui/lab/Timeline";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";

import Grid from "@mui/material/Grid";

import Typography from "@mui/material/Typography";

import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";

function DashRecentSales() {

  var timelinecontentstyle = {
    "background":"ghostwhite",
    "margin":".3rem 0 .3rem 1rem",
    "fontWeight":"400",
    "lineHeight":"1",
    "flex":"1 1 0%",
    "padding":".5rem 1rem",
    "textAlign":"left",
    "fontSize":"17px",
    "border":"1px dashed #ccc",
    "borderRadius":"3px"
  };

  
  var dispatch = useDispatch();
  
  useEffect(() => {
    
    var is_dispatched1 = () => {
      
      ReactSession.get("recent_sales");
      if (ReactSession.get("recent_sales")) {
        return true;
      } else {
        ReactSession.set("recent_sales", "1");
        return false;
      }
    }

    if (!(is_dispatched1())) {
      dispatch(get_recent_sales({ ajax_call: 'recent_sales' }));
    }
   

  }, []);

  var recent_sales = useSelector((state) => state.dash.rsale.rsd);
  // var recent_sales = "";
  
  return (
  
    <>
    
      <Grid style={{"maxHeight": "500px","overflowY":"auto"}}>
        
        <Timeline sx={{ [`& .${timelineItemClasses.root}:before`]: { flex: 0, padding: 0, }, }}>

          {recent_sales !== undefined && recent_sales.length > 0 &&
              
              recent_sales.map((sale) => (
              
                <TimelineItem style={{ minHeight: "57px" }}>
                  
                  <TimelineSeparator>
                    <TimelineDot variant="outlined" color="primary" />
                    <TimelineConnector />
                  </TimelineSeparator>

                  <TimelineContent style={timelinecontentstyle}>
                    
                    <Typography>
                      
                      {sale.order_type === "new" && 
                        <>
                          <p>
                            <span> <a href={"/Orders/" + sale.orderid} style={{ color: "red" }}> <strong>  New : </strong> </a> </span>
                            {new Date(sale.atdate).toLocaleString("default", {month: "short",}) + new Date(sale.atdate).getDate()}
                            &nbsp;&nbsp; 
                            <span> ${sale.order_total.toLocaleString()} </span>
                          </p>
                      </>}

                      {sale.order_type === "repeat" && 
                        <>
                          <p>
                            <span> <a href={"/Orders/" + sale.orderid} style={{ color: "green" }}> <strong> Repeat : </strong>  </a> </span>
                            <strong> {new Date(sale.atdate).toLocaleString("default", { month: "short"}) + " " + new Date(sale.atdate).getDate()} </strong>
                            &nbsp;&nbsp; 
                            <span> ${sale.order_total.toLocaleString()} </span>
                          </p>
                      </>}

                      <span> 
                          <EmailIcon style={{fontSize:'20px',color:'cornflowerblue',verticalAlign:'text-top'}} />  &nbsp;
                          {/* <span> {sale.cusname} </span>  */}
                          <strong style={{verticalAlign:'baseline'}}> {sale.cusmail.toLocaleString()} </strong> 
                      </span>

                      {/* <span> #{sale.orderid}  </span>   */}

                    </Typography>
                    
                  </TimelineContent>

                </TimelineItem>
              )
          )}

        </Timeline>

      </Grid>
      
    </>

  );

}

export default DashRecentSales;
