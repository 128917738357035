import { ReactSession } from "react-client-session";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@mui/material/Grid";
import MaterialTable from "material-table";
import { ThemeProvider, createTheme } from "@mui/material";

//import { get_tops } from "../../features/dash/DashTopsSlice";
import { get_tops } from "../../features/dash/DashTopsSlice";

import { format } from "date-fns";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import startOfYear from "date-fns/startOfYear";

import { addDays, subDays, getDate } from "date-fns";

import { get_init_data } from "../../features/dash/dashboard";

import { DateRangePicker } from "rsuite";
import moment from "moment";

import "rsuite/dist/rsuite.css";

import { Timeline } from "rsuite";

import Button from "@mui/material/Button";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

import Search from "@material-ui/icons/Search";
//import Vieumn from '@material-ui/icons/Vieumn'
import SaveAlt from "@material-ui/icons/SaveAlt";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Add from "@material-ui/icons/Add";
import Check from "@material-ui/icons/Check";
import FilterList from "@material-ui/icons/FilterList";
import ListAltIcon from "@mui/icons-material/ListAlt";
import ClearIcon from "@mui/icons-material/Clear";
import CancelIcon from "@mui/icons-material/Cancel";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Card } from "react-bootstrap";
import DashRecentSales from "./DashRecentSales";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function DashTops() {
  
  const defaultMaterialTheme = createTheme();

  var dispatch = useDispatch();
  var tops = useSelector((state) => state.dashTops);
  
  useEffect(() => {
    
    var is_dispatched1 = () => {
      
      ReactSession.get("get_tops");
      if (ReactSession.get("get_tops")) {
        return true;
      } else {
        ReactSession.set("get_tops", "1");
        return false;
      }
    }

    var is_dispatched2 = () => {
      
      ReactSession.get("get_init_data");
      if (ReactSession.get("get_init_data")) {
        return true;
      } else {
        ReactSession.set("get_init_data", "1");
        return false;
      }
    }

    if (!(is_dispatched1())) {
      dispatch(get_tops());
    }

    if (!(is_dispatched2())) {
      dispatch(get_init_data({ ajax_call: 2 }));
    }

  }, []);

  
  var dash_tops = useSelector((state) => state.dashTops);
  var tops = dash_tops.tops;

  var dash_tm = useSelector((state) => state.dashTops.l30);

  if (dash_tm.l30new) {
    var tmnew = structuredClone(dash_tm.l30new);
    var tmnew_note = dash_tm.l30new_note.split('_note_');
  }

  if (dash_tm.l30ret) {
    var tmret = structuredClone(dash_tm.l30ret);
    var tmret_note = dash_tm.l30ret_note.split('_note_');
  }

  var weekday = dash_tops.weekday;

  if (tops !== undefined && tops !== null) {
    var tpbr = structuredClone(tops.tpbr);
    var tpbp = structuredClone(tops.tpbp);
    var tpbu = structuredClone(tops.tpbu);
    var tcbr = structuredClone(tops.tcbr);
    var tcbu = structuredClone(tops.tcbu);
    var tcbp = structuredClone(tops.tcbp);
  }


  var tmnew_note = [];
  var tmret_note = [];

  var cus_note = [];
  var order_note = [];

  /////////

  var [daterange, setdrange] = useState([
    new Date(moment().startOf("month")),
    new Date(moment().endOf("month")),
    //format(new Date(moment().startOf('month')),'yyyy-MM-dd'),
  ]);

  var [daterange1, setdrange1] = useState([startOfMonth(subDays(new Date(), getDate(new Date()))),endOfMonth(subDays(new Date(), getDate(new Date())))]);

  var [duration, setduration] = useState();

  var dateSubmit = (e) => {
    e.preventDefault();
    dispatch(
      get_init_data({
        from: format(daterange[0], "yyyy-MM-dd"),
        to: format(daterange[1], "yyyy-MM-dd"),
        from1: format(daterange1[0], "yyyy-MM-dd"),
        to1: format(daterange1[1], "yyyy-MM-dd"),
        unit: duration,
        ajax_call: 1,
      })
    );
  };

  var handleChange = (e) => {
    setduration(e.target.value);
  };

  var rev_note = [];
  var prof_note = [];
  var prof_note = [];
  //var cusor_state=[];

  var corp = useSelector((state) => state.dash.corp_chart);

  var labels      = corp.or_l ? corp.or_l.replace(/\"/g, "").split(",") : [];
  var rev_data    = corp.rev_d ? corp.rev_d.replace(/\"/g, "").split(",") : [];
  var profit_data = corp.prof_d ? corp.prof_d.replace(/\"/g, "").split(",") : [];
  var cus_data    = corp.cus_d ? corp.cus_d.replace(/\"/g, "").split(",") : [];
  var order_data  = corp.or_d ? corp.or_d.replace(/\"/g, "").split(",") : [];

  var rev_note      = corp.rev_note && corp.rev_note.split("OO");
  var prof_note     = corp.profit_note && corp.profit_note.split("OO");
  var cus_note      = corp.cus_note;
  var order_note    = corp.order_note;

  var revprof_state = {
    labels: labels,
    datasets: [
      {
        label: 'Revenue',
        background: "rgba(231, 76, 60, 0.7)",
        borderColor: "rgba(231, 76, 60, 1)",
                fill: true,
                pointRadius: 4,
                borderWidth:2,
        data: rev_data,
      }, {
        label: 'Profit',
        backgroundColor: 'rgba(211, 211, 211, 0.5)',
				borderColor: 'rgba(211, 211, 211, 0.3)',
				fill: true,
        pointRadius: 4,
        borderWidth:2,
        data: profit_data,
      }],

  }

  var cusor_state = {
    labels: labels,
    datasets: [{
      label: 'Customer',
      backgroundColor: 'red',
      borderColor: 'rgba(0,0,0,1)',
      borderWidth: 1,
      data: cus_data,
    },
    {
      label: 'Order',
      backgroundColor: 'rgba(75,192,192,1)',
      borderColor: 'rgba(0,0,0,1)',
      borderWidth: 1,
      data: order_data,
    }]
  }


  var options = {
    legend: {
      position: "center",
    },
  };

 
  return (
  
    <>
    
      <Grid item xs={12}>
        
        
        <div className="date-period" style={{ marginBottom: "10px" }}>
          
          <DateRangePicker
            value={daterange}
            onChange={setdrange}
            oneTap={false}
            ranges={[
              {label: "Yesterday",value: [addDays(new Date(), -1), addDays(new Date(), -1)]},
              {label: "Today",value: [new Date(), new Date()]},
              {label: "Tomorrow",value: [addDays(new Date(), 1), addDays(new Date(), 1)]},
              {label: "Last 7 days",value: [subDays(new Date(), 6), new Date()]},
              {label: "This month",value: [subDays(new Date(), getDate(new Date()) - 1),new Date()]},
              {label: "Last month",value: [startOfMonth(subDays(new Date(), getDate(new Date()))),endOfMonth(subDays(new Date(), getDate(new Date())))]},
              {label: "Year To date",value: [startOfYear(new Date()), new Date()]},
            ]}
          >

          </DateRangePicker>

          <DateRangePicker
            value={daterange1}
            onChange={setdrange1}
            oneTap={false}
            ranges={[
              {label: "Yesterday",value: [addDays(new Date(), -1), addDays(new Date(), -1)]},
              {label: "Today",value: [new Date(), new Date()]},
              {label: "Tomorrow",value: [addDays(new Date(), 1), addDays(new Date(), 1)]},
              {label: "Last 7 days",value: [subDays(new Date(), 6), new Date()]},
              {label: "This month",value: [subDays(new Date(), getDate(new Date()) - 1),new Date()]},
              {label: "Last month",value: [startOfMonth(subDays(new Date(), getDate(new Date()))),endOfMonth(subDays(new Date(), getDate(new Date())))]},
              {label: "Year To date",value: [startOfYear(new Date()), new Date()]},
            ]}>
          </DateRangePicker>

          <RadioGroup style={{ display:"inline-block",fontSize:"13px",color:"white",fontWeight:"500" }} onChange={(e)=>{setduration(e.target.value);}}>
          
            <Radio checked={duration === "daily"} value="daily" name="duration" />Day
            <Radio checked={duration === "weekly"} value="weekly" name="duration" /> Week
            <Radio checked={duration === "monthly"} value="monthly" name="duration" /> Month
          
          </RadioGroup>
          
          <Button className="period-btn" variant="contained" color="secondary" onClick={dateSubmit}>Submit</Button>
        
        </div>

      </Grid>
      
        
      <Grid container spacing={3} style={{ padding : "23px" }}>
        
          <Grid item xl={4} lg={4} md={4}  sm={12} xs={12}>
          
            <Card className="dash-card">
              
              <h6>Revenue & Profit</h6>
              
              { revprof_state &&
                revprof_state.labels &&
                Array.isArray(revprof_state.labels) &&
                revprof_state.datasets &&
                Array.isArray(revprof_state.datasets) &&
                revprof_state.datasets.length > 0
                && 
                <Line data={revprof_state} /> 
              }
              
              <div style={{background: "whitesmoke",padding: "20px", textAlign:"center"}}>
                <p><strong>Revenue ::           {rev_note && rev_note[0]}                     </strong></p>
                <p><strong>Revenue/Order ::     {rev_note && rev_note[1]}                     </strong></p>
                <p><strong>Revenue/Customer ::  {rev_note && rev_note[2]}                     </strong></p>
                <p><strong>Profit ::            {prof_note && prof_note[0]}                   </strong></p>
                <p><strong>Profit/Customer  ::  {prof_note && prof_note[1]}                   </strong></p>
              </div>

            </Card>

          </Grid>

          <Grid item xl={4} lg={4} md={4}  sm={12} xs={12}>
            
            <Card className="dash-card">
              
              <h6> Customer & Order </h6>
              
              { cusor_state && cusor_state.labels && Array.isArray(cusor_state.labels) && cusor_state.datasets &&
                Array.isArray(cusor_state.datasets) && cusor_state.datasets.length > 0
                &&  <Line data={cusor_state} options={options} />
              }
              
              <div style={{background: "whitesmoke",padding: "20px", textAlign:"center"}}>
                <p><strong>Customer ::  {cus_note}    </strong></p>
                <p><strong>Order ::     {order_note}  </strong></p>
              </div>

            </Card>

          </Grid>

          <Grid item xl={4} lg={4} md={4}  sm={12} xs={12}>
            
            <Card className="dash-card" 
              style={{'height':'100%',"flexDirection":"column","padding":"15px 20px","borderRadius":"6px","background":"#fff","boxShadow":"0 0 2rem 0 rgba(136,152,170,.15)"}}>
              <h6> Recent Sales </h6>
              <DashRecentSales />
            </Card>

          </Grid>

      </Grid>

      <Grid item xl={6} lg={6} md={6}  sm={12} xs={12}>
        
        {tmnew && tmnew.length > 0 && 
          
          <Card className="dash-card"  style={{boxShadow:'none'}}>
          
              <ThemeProvider theme={defaultMaterialTheme}>
          
                <MaterialTable
                  columns={[
                    {title: "Name",field: "name", render: (row) => <div><a href={"/Customers/profile/" + row.chc}>{row.name}</a></div>},
                    {title: "spend",field: "spend", render: (row) => <div> {row.spend} </div>},
                  ]}
                  data={tmnew}
                  title="This Month New-Customer"
                  icons={{
                    Check: Check,
                    DetailPanel: ChevronRight,
                    Export: SaveAlt,
                    Filter: FilterList,
                    FirstPage: FirstPage,
                    LastPage: LastPage,
                    NextPage: ChevronRight,
                    PreviousPage: ChevronLeft,
                    Search: Search,
                    ResetSearch: CancelIcon,
                    Clear: CancelIcon,
                  }}
                  options={{
                    showFirstLastPageButtons: false,
                    pageSize: 10, // make initial page size
                    emptyRowsWhenPaging: false, // To avoid of having empty rows
                    pageSizeOptions: [10, 15, 25, 40, 50],
                    search: false,
                    searchFieldAlignment: "right",
                    exportButton: true,
                    exportAllData: true,
                    cellStyle: {
                      padding: "4px",
                      lineHeight: 2,
                      fontFamily: "Circular-Loom",
                      textAlign: "center",
                    },
                    rowStyle: (data, index) =>
                      index % 2 === 0
                        ? { background: "white" }
                        : { background: "white" },
                  }}
                  localization={{
                    pagination: {
                      labelRowsPerPage: "",
                      showFirstLastPageButtons: false,
                      showPageSizeOptions: false,
                      showPageJump: false,
                    },
                  }}
                />

              </ThemeProvider>

              <div style={{background: "whitesmoke",padding: "20px", textAlign:"center"}}>
                <p><strong> Total : {tmnew_note !== undefined && tmnew_note[0]} </strong></p>
                <p><strong> Spent : {tmnew_note !== undefined && tmnew_note[1]} </strong></p>
                <p><strong> Avg. Spent : {tmnew_note !== undefined && tmnew_note[2]} </strong></p>
              </div>

          </Card>
        }

      </Grid>

      
      <Grid item xl={6} lg={6} md={6}  sm={12} xs={12}>
      
        {tmret && tmret.length > 0 && 
      
          <Card className="dash-card">
            
            <ThemeProvider theme={defaultMaterialTheme}>
              <MaterialTable
                columns={[
                  {title: "Name",field: "name",render: (row) => <div><a href={"/Customers/profile/" + row.chc}>{row.name}</a></div>},
                  {title: "spend",field: "spend",render: (row) => <div> {row.spend} </div>}
                ]}
                data={tmret}
                title="This month Repeat Customer"
                icons={{
                  Check: Check,
                  DetailPanel: ChevronRight,
                  Export: SaveAlt,
                  Filter: FilterList,
                  FirstPage: FirstPage,
                  LastPage: LastPage,
                  NextPage: ChevronRight,
                  PreviousPage: ChevronLeft,
                  Search: Search,
                  ResetSearch: CancelIcon,
                  Clear: CancelIcon,
                }}
                options={{
                  showFirstLastPageButtons: false,
                  pageSize: 10, // make initial page size
                  emptyRowsWhenPaging: false, // To avoid of having empty rows
                  pageSizeOptions: [10, 15, 25, 40, 50],
                  search: false,
                  searchFieldAlignment: "right",
                  exportButton: true,
                  exportAllData: true,
                  cellStyle: {
                    padding: "4px",
                    lineHeight: 2,
                    textAlign: "center",
                  },
                  // rowStyle: {
                  //     backgroundColor: '#EEE',
                  // }
                  rowStyle: (data, index) =>
                    index % 2 === 0
                      ? { background: "white " }
                      : { background: "white" },
                }}
                localization={{
                  pagination: {
                    labelRowsPerPage: "",
                    showFirstLastPageButtons: false,
                    showPageSizeOptions: false,
                    showPageJump: false,
                  },
                }}
              />
              
            </ThemeProvider>

              <div style={{background: "whitesmoke",padding: "20px", textAlign:"center"}}>
                <p><strong> Total : {tmret_note !== undefined && tmret_note[0]} </strong></p>
                <p><strong> Spent : {tmret_note !== undefined && tmret_note[1]} </strong></p>
                <p><strong> Avg. Spent : {tmret_note !== undefined && tmret_note[2]} </strong></p>
              </div>

          </Card>
        }
      </Grid>


      <Grid item xl={4} lg={4} md={4}  sm={12} xs={12}>

        {tpbr && tpbr.length > 0 && 

          <Card className="dash-card">
            
            <ThemeProvider theme={defaultMaterialTheme}>
              
              <MaterialTable
                columns={[
                  {title: "Product",field: "proname",render: (row) => <div> {row.proname} </div>},
                  {title: "Revenue",field: "revenue",render: (row) => <div> {row.revenue} </div>},
                  {title: "%",field: "percentage",render: (row) => <div> {row.percent} </div>},
                ]}
                data={tpbr}
                title="Top Product By Revenue"
                icons={{
                  Check: Check,
                  DetailPanel: ChevronRight,
                  Export: SaveAlt,
                  Filter: FilterList,
                  FirstPage: FirstPage,
                  LastPage: LastPage,
                  NextPage: ChevronRight,
                  PreviousPage: ChevronLeft,
                  Search: Search,
                  ResetSearch: CancelIcon,
                  Clear: CancelIcon,
                }}
                options={{
                  showFirstLastPageButtons: false,
                  pageSize: 10, // make initial page size
                  emptyRowsWhenPaging: false, // To avoid of having empty rows
                  pageSizeOptions: [10, 15, 25, 40, 50],
                  search: true,
                  searchFieldAlignment: "right",
                  exportButton: true,
                  exportAllData: true,
                  cellStyle: {
                    padding: "4px",
                    lineHeight: 2,
                    fontFamily: "Circular-Loom",
                    textAlign: "center",
                    borderBottom: "1px solid rgb(246, 224, 224)",
                  },
                }}
                localization={{
                  pagination: {
                    labelRowsPerPage: "",
                    showFirstLastPageButtons: false,
                    showPageSizeOptions: false,
                    showPageJump: false,
                  },
                }}
              />
            </ThemeProvider>
          </Card>
        }
      </Grid>

      <Grid item xl={4} lg={4} md={4}  sm={12} xs={12}>
        {tpbu && tpbu.length > 0 && (
          <Card className="dash-card">
            <ThemeProvider theme={defaultMaterialTheme}>
              <MaterialTable
                columns={[
                  { title: "product",render: (row) => <div> {row.proname} </div>},
                  { title: "unit",   render: (row) => <div> {row.unit} </div> },
                  { title: "%",      render: (row) => <div> {row.percent} </div> },
                ]}
                data={tpbu}
                title="Top Product By Unit"
                icons={{
                  Check: Check,
                  DetailPanel: ChevronRight,
                  Export: SaveAlt,
                  Filter: FilterList,
                  FirstPage: FirstPage,
                  LastPage: LastPage,
                  NextPage: ChevronRight,
                  PreviousPage: ChevronLeft,
                  Search: Search,
                  ResetSearch: CancelIcon,
                  Clear: CancelIcon,
                }}
                options={{
                  showFirstLastPageButtons: false,
                  pageSize: 10, // make initial page size
                  emptyRowsWhenPaging: false, // To avoid of having empty rows
                  pageSizeOptions: [10, 15, 25, 40, 50],
                  search: true,
                  searchFieldAlignment: "right",
                  exportAllData: true,
                  cellStyle: {
                    padding: "4px",
                    lineHeight: 2,
                    fontFamily: "Circular-Loom",
                    textAlign: "center",
                    borderBottom: "2px solid rgb(246, 224, 224)",
                  },
                }}
                localization={{
                  pagination: {
                    labelRowsPerPage: "",
                    showFirstLastPageButtons: false,
                    showPageSizeOptions: false,
                    showPageJump: false,
                  },
                }}
              />
            </ThemeProvider>
          </Card>
        )}
      </Grid>

      <Grid item xl={4} lg={4} md={4}  sm={12} xs={12}>
        {tpbp && tpbp.length > 0 && (
          <Card className="dash-card">
            <ThemeProvider theme={defaultMaterialTheme}>
              <MaterialTable
                columns={[
                  {title: "Product",render: (row) => <div> {row.proname} </div>},
                  {title: "Profit",render: (row) => <div> {row.profit} </div>},
                  {title: "%", render: (row) => <div> {row.percent} </div> },
                ]}
                data={tpbp}
                title="Top Product By Profit"
                icons={{
                  Check: Check,
                  DetailPanel: ChevronRight,
                  Export: SaveAlt,
                  Filter: FilterList,
                  FirstPage: FirstPage,
                  LastPage: LastPage,
                  NextPage: ChevronRight,
                  PreviousPage: ChevronLeft,
                  Search: Search,
                  ResetSearch: CancelIcon,
                  Clear: CancelIcon,
                }}
                options={{
                  showFirstLastPageButtons: false,
                  pageSize: 10, // make initial page size
                  emptyRowsWhenPaging: false, // To avoid of having empty rows
                  pageSizeOptions: [10, 15, 25, 40, 50],
                  search: true,
                  searchFieldAlignment: "right",
                  exportButton: true,
                  exportAllData: true,
                  cellStyle: {
                    padding: "4px",
                    lineHeight: 2,
                    fontFamily: "Circular-Loom",
                    textAlign: "center",
                    borderBottom: "2px solid rgb(246, 224, 224)",
                  },
                }}
                localization={{
                  pagination: {
                    labelRowsPerPage: "",
                    showFirstLastPageButtons: false,
                    showPageSizeOptions: false,
                    showPageJump: false,
                  },
                }}
              />
            </ThemeProvider>
          </Card>
        )}
      </Grid>

      <Grid item xl={4} lg={4} md={4}  sm={12} xs={12}>
        {tcbr && tcbr.length > 0 && (
          <Card className="dash-card">
            <ThemeProvider theme={defaultMaterialTheme}>
              <MaterialTable
                columns={[
                  {title: "Category",render: (row) => <div> {row.category} </div>},
                  {title: "Revenue", render: (row) => <div> {row.revenue} </div>},
                  { title: "%", render: (row) => <div> {row.percent} </div> },
                ]}
                data={tcbr}
                title="Top Category By Revenue"
                icons={{
                  Check: Check,
                  DetailPanel: ChevronRight,
                  Export: SaveAlt,
                  Filter: FilterList,
                  FirstPage: FirstPage,
                  LastPage: LastPage,
                  NextPage: ChevronRight,
                  PreviousPage: ChevronLeft,
                  Search: Search,
                  ResetSearch: CancelIcon,
                  Clear: CancelIcon,
                }}
                options={{
                  showFirstLastPageButtons: false,
                  pageSize: 10, // make initial page size
                  emptyRowsWhenPaging: false, // To avoid of having empty rows
                  pageSizeOptions: [10, 15, 25, 40, 50],
                  search: true,
                  searchFieldAlignment: "right",
                  exportButton: true,
                  exportAllData: true,
                  cellStyle: {
                    padding: "4px",
                    lineHeight: 2,
                    fontFamily: "Circular-Loom",
                    textAlign: "center",
                    borderBottom: "2px solid rgb(246, 224, 224)",
                  },
                }}
                localization={{
                  pagination: {
                    labelRowsPerPage: "",
                    showFirstLastPageButtons: false,
                    showPageSizeOptions: false,
                    showPageJump: false,
                  },
                }}
              />
            </ThemeProvider>
          </Card>
        )}
      </Grid>

      <Grid item xl={4} lg={4} md={4}  sm={12} xs={12}>
        {tcbu && tcbu.length > 0 && (
          <Card className="dash-card">
            <ThemeProvider theme={defaultMaterialTheme}>
              <MaterialTable
                columns={[
                  { title: "Category",render: (row) => <div> {row.category} </div>},
                  { title: "Unit", render: (row) => <div> {row.unit} </div> },
                  { title: "%", render: (row) => <div> {row.percent} </div> },
                ]}
                data={tcbu}
                title="By-Unit"
                icons={{
                  Check: Check,
                  DetailPanel: ChevronRight,
                  Export: SaveAlt,
                  Filter: FilterList,
                  FirstPage: FirstPage,
                  LastPage: LastPage,
                  NextPage: ChevronRight,
                  PreviousPage: ChevronLeft,
                  Search: Search,
                  ResetSearch: CancelIcon,
                  Clear: CancelIcon,
                }}
                options={{
                  showFirstLastPageButtons: false,
                  pageSize: 10, // make initial page size
                  emptyRowsWhenPaging: false, // To avoid of having empty rows
                  pageSizeOptions: [10, 15, 25, 40, 50],
                  search: true,
                  searchFieldAlignment: "right",
                  exportButton: true,
                  exportAllData: true,
                  cellStyle: {
                    padding: "4px",
                    lineHeight: 2,
                    fontFamily: "Circular-Loom",
                    textAlign: "center",
                    borderBottom: "2px solid rgb(246, 224, 224)",
                  },
                }}
                localization={{
                  pagination: {
                    labelRowsPerPage: "",
                    showFirstLastPageButtons: false,
                    showPageSizeOptions: false,
                    showPageJump: false,
                  },
                }}
              />
            </ThemeProvider>
          </Card>
        )}
      </Grid>

      <Grid item xl={4} lg={4} md={4}  sm={12} xs={12}>
        {tcbp && tcbp.length > 0 && (
          <Card className="dash-card">
            <ThemeProvider theme={defaultMaterialTheme}>
              <MaterialTable
                columns={[
                  { title: "Category", render: (row) => <div> {row.category} </div>},
                  { title: "Profit",render: (row) => <div> {row.profit} </div>},
                  { title: "%", render: (row) => <div> {row.percent} </div> },
                ]}
                data={tcbp}
                title="By-Profit"
                icons={{
                  Check: Check,
                  DetailPanel: ChevronRight,
                  Export: SaveAlt,
                  Filter: FilterList,
                  FirstPage: FirstPage,
                  LastPage: LastPage,
                  NextPage: ChevronRight,
                  PreviousPage: ChevronLeft,
                  Search: Search,
                  ResetSearch: CancelIcon,
                  Clear: CancelIcon,
                }}
                options={{
                  showFirstLastPageButtons: false,
                  pageSize: 10, // make initial page size
                  emptyRowsWhenPaging: false, // To avoid of having empty rows
                  pageSizeOptions: [10, 15, 25, 40, 50],
                  search: true,
                  searchFieldAlignment: "right",
                  exportButton: true,
                  exportAllData: true,
                  cellStyle: {
                    padding: "4px",
                    lineHeight: 2,
                    fontFamily: "Circular-Loom",
                    textAlign: "center",
                    borderBottom: "2px solid rgb(246, 224, 224)",
                  },
                }}
                localization={{
                  pagination: {
                    labelRowsPerPage: "",
                    showFirstLastPageButtons: false,
                    showPageSizeOptions: false,
                    showPageJump: false,
                  },
                }}
              />
            
            </ThemeProvider>
          
          </Card>
        
        )}

      </Grid>

    </>
    
  );

}

export default DashTops;
