import { ReactSession } from "react-client-session";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Multiselect from "multiselect-react-dropdown";

import Search from "@material-ui/icons/Search";
import SaveAlt from "@material-ui/icons/SaveAlt";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Check from "@material-ui/icons/Check";
import FilterList from "@material-ui/icons/FilterList";
import ListAltIcon from "@mui/icons-material/ListAlt";
import CancelIcon from "@mui/icons-material/Cancel";

import axios from "axios";
import MaterialTable from "material-table";
import { ThemeProvider, createTheme, Button } from "@mui/material";

import { get_product_and_catagory_and_sku_data } from "../../features/product/ProductListAndSegment";
import { get_filtered_product_data } from "../../features/product/ProductListAndSegment";
import { get_product_segments } from "../../features/product/ProductListAndSegment";

import Products from "./ProductSegmentFilters/Products";
import ProductCatagory from "./ProductSegmentFilters/ProductCatagory";
import ProductSKU from "./ProductSegmentFilters/ProductSKU";
import ProductCurrentPrice from "./ProductSegmentFilters/ProductCurrentPrice";
import ProductHeight from "./ProductSegmentFilters/ProductHeight";
import ProductWidth from "./ProductSegmentFilters/ProductWidth";
import ProductLength from "./ProductSegmentFilters/ProductLength";
import ProductWeight from "./ProductSegmentFilters/ProductWeight";
import ProductStock from "./ProductSegmentFilters/ProductStock";
import ProductOnSale from "./ProductSegmentFilters/ProductOnSale";
import ProductType from "./ProductSegmentFilters/ProductType";

import ProductFilter from "./ProductFilter";
import { get_products_from_selected_catagory } from "../../features/product/ProductListAndSegment";
import Grid from "@mui/material/Grid";
//import SingleProduct from '../../pages/SingleProduct';
import SingleProduct from "../SinPro/SingleProduct";
//import SideNav from '../../pages/SideNav';

import { EditCost,EditCurrentPrice,EditRegulerPrice } from "../../features/product/ProductListAndSegment";
import { Card } from "react-bootstrap";
import NavButton from "../../pages/NavButton";



function ProductListAndSegments() {

  var dispatch = useDispatch();
  const defaultMaterialTheme = createTheme();
  var [segname, setSegname] = useState("Orders from last 6month/s");

 
  useEffect(() => {

    var is_dispatched1 = () => {

      ReactSession.get("get_product_and_catagory_and_sku_data");
      if (ReactSession.get("get_product_and_catagory_and_sku_data")) {
        return true;
      } else {
        ReactSession.set("get_product_and_catagory_and_sku_data", "1");
        return false;
      }
    }

    var is_dispatched2 = () => {

      ReactSession.get("get_product_segments");
      if (ReactSession.get("get_product_segments")) {
        return true;
      } else {
        ReactSession.set("get_product_segments", "1");
        return false;
      }
    }

    
    if (!(is_dispatched1())) {
      dispatch(get_product_and_catagory_and_sku_data({ ajax_call: 2 }));
      console.log('dispatched :: get_product_and_catagory_and_sku_data');
    }

    if (!(is_dispatched2())) {
      dispatch(get_product_segments({ ajax_call: 2 }));
      console.log('dispatched :: get_product_segments');
    }

  }, [])


  var Product_list_seg = useSelector((state) => state.product_List_And_Segments);


  var product_table = Product_list_seg.product_table_object;
  if (product_table && product_table.length > 0)
    var product_table_clone = structuredClone(product_table);

  var cat_table = Product_list_seg.product_cat_table_object;
  if (cat_table && cat_table.length > 0)
    var cat_table_clone = structuredClone(cat_table);


  var Update_cog = (event) => {
    
    event.preventDefault();
    const fdata = new FormData(event.target);
    const data = Object.fromEntries(fdata.entries());

    axios.post("https://server.shopex.io/products/product_cost_update.php", data, {withCredentials: true, })
      .then(function (response) {})
      .catch(function (error) {
        console.log(error);
      });
  };


  var Update_Current_price = (event) => {
    
    event.preventDefault();
    const fdata = new FormData(event.target);
    const data = Object.fromEntries(fdata.entries());

    axios.post("https://server.shopex.io/webhooks/wh_update_product_current_price.php", data, {withCredentials: true, })
      .then(function (response) {})
      .catch(function (error) {
        console.log(error);
      });
  };

  var Update_Regular_price = (event) => {
    
    event.preventDefault();
    const fdata = new FormData(event.target);
    const data = Object.fromEntries(fdata.entries());

    axios.post("https://server.shopex.io/webhooks/wh_update_product_regular_price.php", data, {withCredentials: true, })
      .then(function (response) {})
      .catch(function (error) {
        console.log(error);
      });
  };


  var [filterList_, setfilterList] = useState([]);

  var [filts, setfils] = useState(["Products", "Catagory", "SKU", "Current_Price", "Height", "Width", "Length", "Weight", "Stock", "on_sale", "Type"]);

  var addfilter = (e, arg) => {
    // cus-Filter De-selested from Dropdown
    if (arg === 99) {
      // Get previous state
      var prev_state = JSON.parse(localStorage.getItem("shopex_Product_filts"));
      console.log(prev_state);

      // Get Removed state
      var removed_filter = prev_state.filter((x) => !e.includes(x));
      var remfil = removed_filter[0];

      var newfils = filterList_.filter((item) => item.key !== remfil);
      setfilterList(newfils);

      // Update The latest selected's as previous state in local-Storage
      localStorage.setItem("shopex_Product_filts", JSON.stringify(e));
    }

    // cus-Filter Selected from Dropdown
    if (arg !== 99) {
      localStorage.setItem("shopex_Product_filts", JSON.stringify(e));
      if (arg === "Products")       {setfilterList(filterList_.concat(<Products key={"Products"} />));}
      if (arg === "Catagory")       {setfilterList(filterList_.concat(<ProductCatagory key={"Catagory"} />));}
      if (arg === "SKU")            {setfilterList(filterList_.concat(<ProductSKU key={"SKU"} />));}
      if (arg === "Current_Price")  {setfilterList(filterList_.concat(<ProductCurrentPrice key={"Current_Price"} />));}
      if (arg === "Height")         {setfilterList(filterList_.concat(<ProductHeight key={"Height"} />));}
      if (arg === "Width")          {setfilterList(filterList_.concat(<ProductWidth key={"Width"} />));}
      if (arg === "Length")         {setfilterList(filterList_.concat(<ProductLength key={"Length"} />));}
      if (arg === "Weight")         {setfilterList(filterList_.concat(<ProductWeight key={"Weight"} />));}
      if (arg === "Stock")          {setfilterList(filterList_.concat(<ProductStock key={"Stock"} />));}
      if (arg === "on_sale")        {setfilterList(filterList_.concat(<ProductOnSale key={"on_sale"} />));}
      if (arg === "Type")           {setfilterList(filterList_.concat(<ProductType key={"Type"} />));}
    }
  };

  var showSingleCatPrduct = (e) => {};

  var filterSubmit = (event) => {
    event.preventDefault();
    const fdata = new FormData(event.target);
    const data = Object.fromEntries(fdata.entries());
    data["ajax_call"] = 1;
    setSegname("");
    dispatch(get_filtered_product_data(data));
  };

  var product_obj = useSelector((state) => state.product_List_And_Segments.all_product_object);
  var ops = [];
  if (product_obj && product_obj.length > 0) {
    for (var i of product_obj) {
      var label = i.product_name;
      var value = i.product_id;
      ops.push({ value: value, label: label });
    }
  }

  var [filterList1, setfilterList1] = useState([]);

  var product_selected_deselected = (e, arg) => {
    // cus-Filter De-selested from Dropdown
    if (arg === 99) {
      // Get previous state
      var prev_state = JSON.parse(
        localStorage.getItem("shopex_Product_cusseg_filts")
      );
      console.log(prev_state);

      // Get Removed state
      var removed_filter = prev_state.filter((x) => !e.includes(x));
      var remfil = removed_filter[0];

      var newfils = filterList1.filter((item) => item.key !== remfil);
      setfilterList1(newfils);

      // Update The latest selected's as previous state in local-Storage
      localStorage.setItem("shopex_Product_cusseg_filts", JSON.stringify(e));
    }

    // cus-Filter Selected from Dropdown
    if (arg !== 99) {
      localStorage.setItem("shopex_Product_cusseg_filts", JSON.stringify(e));
      setfilterList1(
        filterList1.concat(<ProductFilter key={arg} productnid={arg} />)
      );
    }
  };

  var [seletedpros, setSeletedPros] = useState("");
  var [billCity_, setbillCity_] = useState(false);
  var [shipCity_, setshipCity_] = useState(false);
  var [BillShip, setBillShip] = useState("");

  var handleCogChange = (e) => {
    var id = e.target.name;
    var cost = e.target.value;
    dispatch(EditCost({ id: id, cost: cost }));
  };


  var handle_Regular_Price_Change = (e) => {
    var id            = e.target.name;
    var reguler_price = e.target.value;
    dispatch(EditRegulerPrice({ id: id, reguler_price: reguler_price }));
  };

  var handle_Current_Price_Change = (e) => {
    var id         = e.target.name;
    var curr_price = e.target.value;
    dispatch(EditCurrentPrice({ id: id, curr_price: curr_price }));
  };


  return (
    
    <Grid container spacing={3}>
    
      <Grid item xs={12} className="top-wrap">
        <div className="notifications">
          <h6>Product : List and segments</h6>
          <div className="notify"><NavButton /></div>
        </div>
      </Grid>
    
      <Grid item xs={12}>
        
        <Multiselect
          isObject={false}
          placeholder=" + Add Filter"
          onRemove={(e) => {addfilter(e, 99);}}
          onSelect={(e) => {addfilter(e, e[e.length - 1]);}}
          options={filts}
          selectedValues={[]}
          showCheckbox
        />

        {/* {Seglist && 
            <Group position="left" style={{marginTop:'15px'}} >
            <Button onClick={() => setOpened(true)}>Order Segments</Button>
            </Group>
        }*/}

      </Grid>

      <Grid item xs={12}>
        
        <form className="dash-card" onSubmit={filterSubmit}>
          {filterList_.length > 0 && 
            <div className="input-filters">
              <strong>Create Segment :</strong>
              <input type="text" name="segname" size="45" placeholder="Insert segment name"/>
            </div>
          }
          {filterList_}
          {filterList_.length > 0 && <input type="submit" style={{ marginTop: ".5rem" }} value="Submit"/>}
        
        </form>

      </Grid>

      <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
        
        <Card style={{"background": "#fff","border-radius": "6px",
                    "box-shadow": "0 0 2rem 0 rgba(136,152,170,.15)",
                    "display": "flex",
                    "flex-direction": "column",
                    "overflow": "hidden"}}>
        
          <ThemeProvider theme={defaultMaterialTheme}>
            {
              cat_table_clone && 
              <MaterialTable
                style={{ borderRadius: "14px" }}
                columns={[{title: "",field: "catagory_name",render: (row) => (<div style={{ color: "#5e72e4" }}>{row.catagory_name}</div>)}]}
                onRowClick={(event, rowData) => { dispatch(get_products_from_selected_catagory({id: rowData.catagory_id,see_sin_cat: 1,}));}}
                data={cat_table_clone}
                title="Catagories"
                actions={[{icon: ListAltIcon,tooltip: "Fetch"}]}
                icons={{
                  Check: Check,
                  DetailPanel: ChevronRight,
                  Export: SaveAlt,
                  Filter: FilterList,
                  FirstPage: FirstPage,
                  LastPage: LastPage,
                  NextPage: ChevronRight,
                  PreviousPage: ChevronLeft,
                  Search: Search,
                  ResetSearch: CancelIcon,
                  Clear: CancelIcon,
                }}
                options={{
                  pageSize: 10,
                  pageSizeOptions: [],
                  showFirstLastPageButtons: false, // make initial page size
                  emptyRowsWhenPaging: false, // To avoid of having empty rows
                  search: true,
                  searchFieldAlignment: "right",
                  exportButton: true,
                  exportAllData: true,
                  cellStyle: {
                    padding: "4px",
                  },
                }}
                localization={{
                  pagination: {
                    labelRowsPerPage: "",
                  },
                  header: {
                    actions: "",
                  },
                }}
              />
            }
          </ThemeProvider>

        </Card>

      </Grid>

      <Grid item xl={10} lg={10} md={10} sm={12} xs={12}>

        <Card className="dash-card">
          <ThemeProvider theme={defaultMaterialTheme}>
            { product_table_clone && 
              <MaterialTable
                columns={[
                  {title: "Image",field: "",render: (row) => <img style={{ maxWidth: "80px", borderRadius: "4px" }} 
                      src="https://www.mountaingoatsoftware.com/uploads/blog/2016-09-06-what-is-a-product.png" /> },
                  {title: "Product",field: "product_name",
                    render: (row) => 
                      <div  style={{color: "#5e72e4",minWidth: "300px",fontFamily: "system-ui",textAlign: "center"}}>
                        <a style={{ color: "#5e72e4" }} href={"/Products/" + row.product_id}>{row.product_name}</a>
                      </div>
                  },
                  { title: "Regular Price",field: "reguler_price",
                    // render: (row) => <div style={{ background: "whitesmoke" }}>{row.reguler_price}</div>
                    render: (row) => <> { row.product_type == "simple" && 
                      <input name={row.product_id} form="RegularPriceform" type="number" placeholder={row.reguler_price} style={{ padding: "0 10px" ,  width: '100px'  }} 
                      onChange={handle_Regular_Price_Change}/>
                    }
                    </>
                  },
                  {title: "Current Price",field: "curr_price", 
                    //render: (row) => <div style={{ background: "ghostwhite" }}>{row.curr_price}</div>
                    render: (row) => <> { row.product_type == "simple" && 
                      <input name={row.product_id} form="CurrentPriceform" type="number" placeholder={row.curr_price} style={{ padding: "0 10px", width: '100px'  }} 
                      onChange={handle_Current_Price_Change}/>
                    }
                    </>
                  },

                  {title: "On-Stock",field: "stock_quantity",render: (row) => <div style={{ background: "whitesmoke", width: '70px'  }}>{row.stock_quantity}</div>},
                  {title: "Type",field: "product_type",render: (row) => <div style={{ background: "ghostwhite" }}>{row.product_type}</div>},
                  {title: "cost",field: "cog",
                    render: (row) => <> { row.product_type == "simple" && 
                    <input name={row.product_id} form="cogform" type="number" placeholder={row.cog} style={{ padding: "0 10px", width: '100px'  }} onChange={handleCogChange}/>
                    }
                    </>
                  },
                  // {title:"",field:"",render: (row) => <input form="cogform" type="submit" style={{ padding: "2px 12px",fontWeight: "600", width: '100px'  }} value="Save-COG"/>}
                  
                ]}

                data={product_table_clone}
                //title="Current Products"
                
                title={
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ marginRight: '10px' }}>Current Products</span>
                    <input form="cogform" type="submit" style={{ fontWeight: "600", marginRight:"10px"  }} value="Save-Cost"/>
                    <input form="CurrentPriceform" type="submit" value="Save-CurrentPrice" style={{ marginRight:"10px", fontWeight: "600"}}/>
                    <input form="RegularPriceform" type="submit" value="Save-RegularPrice" style={{ fontWeight: "600" }}/>
                  </div>
                }

                icons={{
                  Check: Check,
                  DetailPanel: ChevronRight,
                  Export: SaveAlt,
                  Filter: FilterList,
                  FirstPage: FirstPage,
                  LastPage: LastPage,
                  NextPage: ChevronRight,
                  PreviousPage: ChevronLeft,
                  Search: Search,
                  ResetSearch: CancelIcon,
                }}
                options={{
                  pageSize: 10,
                  showFirstLastPageButtons: false, // make initial page size
                  emptyRowsWhenPaging: false, // To avoid of having empty rows
                  pageSizeOptions: [10, 15, 25, 40, 50],
                  search: true,
                  searchFieldAlignment: "right",
                  exportButton: true,
                  exportAllData: true,
                  cellStyle: {
                    padding: "4px",
                    lineHeight: 2,
                    fontFamily: "system-ui",
                    textAlign: "center",
                    borderBottom: "2px solid rgb(246, 224, 224)",
                  },
                }}
                localization={{
                  pagination: {
                    labelRowsPerPage: "",
                    showFirstLastPageButtons: false,
                  },
                }}
              />
            }
          </ThemeProvider>
        </Card>
      </Grid>
    
      <form onSubmit={Update_cog} id="cogform"></form>

      <form onSubmit={Update_Regular_price} id="RegularPriceform"></form>

      <form onSubmit={Update_Current_price} id="CurrentPriceform"></form>
    
    </Grid>

  );
}

export default ProductListAndSegments;
