import React, { useState, useRef } from "react";
import { TextInput, PasswordInput, Tooltip, Center, Text, Button } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function SignUpForm() {

  var [EmailCheck, setEmailCheck] = useState(false);

  var navigate    = useNavigate();
  var navigateRef = useRef(navigate);

  var [opened, setOpened]       = useState(false);
  var [value, setValue]         = useState('');
  var valid                     = value.trim().length >= 8;


  var [email, setEmail]                       = useState('');
  var [name, setName]                         = useState('');
  var [password, setPassword]                 = useState('');
  var [confirmPassword, setConfirmPassword]   = useState('');
  var [passwordMatch, setPasswordMatch]       = useState(false);


  var handleSubmit = (event) => {
    
    event.preventDefault();

    //var fdata = new FormData(event.target);
    //var data = Object.fromEntries(fdata.entries());

    var data = {
      email: email,
      name:name,
      pcode: password,
    };

    axios.post('https://server.shopex.io/registration/reg_form_submit.php', data)
      .then(function (response) {
        if (response.data.res === 'taken') {
          setEmailCheck(true);
        } else if (response.data.res === 'welcome') {
          localStorage.setItem('soid', JSON.stringify(response.data.o));
          navigateRef.current('/RegVerify');
        } else if (response === 'ppp') {
          // window.location.href="https://shopex.io/dash.php";
        } else if (response.data === 'Not') {
          alert("No Invitetion Sent to you");
        }
      })
      .catch(function (error) {
        console.log(error);
      })
  };


  var handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.currentTarget.value);
    setPasswordMatch(event.currentTarget.value === password);
  };


  return (
    
    <>

      <form onSubmit={handleSubmit}>
        
        <TextInput label="Your Full Name" placeholder="Your Name" onChange={(event) => setName(event.currentTarget.value)}  />
        
        <TextInput mt="md" label="Your Eamil" placeholder="Your Email Address" onChange={(event) => setEmail(event.currentTarget.value)} />

        <Tooltip label={valid ? 'All good!' : 'Password must include at least 6 characters'} position="bottom-start" withArrow opened={opened}
          
          color={valid ? 'indigo' : undefined}>
          <PasswordInput label="Your password" required placeholder="Your password"
            onFocus={() => setOpened(true)} onBlur={() => setOpened(false)}  mt="md" 
            onChange={(event) => setPassword(event.currentTarget.value)}/>

        </Tooltip>

        <Tooltip label={valid ? 'All good!' : 'Password must include at least 6 characters'}
            position="bottom-start" withArrow opened={opened} color={valid ? 'indigo' : undefined} >
        
          <PasswordInput
            label={passwordMatch ? 'Passwords match' : 'Passwords do not match'} required
            placeholder="Confirm password" 
            onFocus={() => setOpened(true)} 
            onBlur={() => setOpened(false)} mt="md" value={confirmPassword} 
            onChange={(event) => handleConfirmPasswordChange(event)}/>
              
        </Tooltip>
 
        <Button type="submit" fullWidth mt='xl' px='xl' size="md" color="indigo">Submit</Button>
        
        {EmailCheck && <h3> email already exist </h3>}

      </form>

    </>

  );

}

export default SignUpForm
