import React from "react";
import Products from "../components/SinCus/Products";

function SingleCustomer() {
  return (
    <>
      <Products />
    </>
  );
}

export default SingleCustomer;
