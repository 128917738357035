import React from "react";

function OrderID() {
  return (
    <div className="input-filters">
      <strong> Order-ID :</strong>
      <input className="multi" defaultValue="" type="text" name="o_id" />
    </div>
  );
}

export default OrderID;
