import { ReactSession } from "react-client-session";
import React, { useEffect, useState } from "react";

import { Line } from "react-chartjs-2";
import { useSelector, useDispatch } from "react-redux";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

import MaterialTable from "material-table";
import { ThemeProvider, createTheme } from "@mui/material";

import Search from "@material-ui/icons/Search";
import SaveAlt from "@material-ui/icons/SaveAlt";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Check from "@material-ui/icons/Check";
import FilterList from "@material-ui/icons/FilterList";
import CancelIcon from "@mui/icons-material/Cancel";

import { format } from "date-fns";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import startOfYear from "date-fns/startOfYear";
import moment from "moment";

import { addDays, subDays, getDate } from "date-fns";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.css";

import Grid from "@mui/material/Grid";

import Products from "./ProductSegmentFilters/Products";
import ProductCatagory from "./ProductSegmentFilters/ProductCatagory";

import { get_performance_data } from "../../features/product/ProductPerformance";
import { get_product_and_catagory_and_sku_data } from "../../features/product/ProductListAndSegment";

import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";

import RepeatIcon from "@mui/icons-material/Repeat";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import LayersRoundedIcon from "@mui/icons-material/LayersRounded";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { Card } from "react-bootstrap";
import NavButton from "../../pages/NavButton";

import Select from "react-select";

function ProductPerformance() {

  var dispatch = useDispatch();

  var defaultMaterialTheme = createTheme();

  useEffect(() => {
    
    var is_dispatched2 = () => {
      ReactSession.get("get_product_and_catagory_and_sku_data");
      if (ReactSession.get("get_product_and_catagory_and_sku_data")) {
        return true;
      } else {
        ReactSession.set("get_product_and_catagory_and_sku_data", "1");
        return false;
      }
    };

    if (!is_dispatched2()) {
      dispatch(get_product_and_catagory_and_sku_data({ ajax_call: 2 }));
    }
    
  }, []);


  var cat_obj = useSelector((state) => state.product_List_And_Segments.product_cat_table_object);

  var ops1 = [];
  if (cat_obj && cat_obj.length > 0) {
    for (var i of cat_obj) {
      var label = i.catagory_name;
      var value = i.catagory_id;
      ops1.push({ value: value, label: label });
    }
  }

  var [daterange, setdrange] = useState([
    new Date(moment().startOf("month")),
    new Date(moment().endOf("month")),
    //format(new Date(moment().startOf('month')),'yyyy-MM-dd'),
  ]);

  var [duration, setDuration] = useState();

  var dateSubmit = (event) => {
    event.preventDefault();
    var fdata = new FormData(event.target);
    var data = Object.fromEntries(fdata.entries());
    //setSegname('');
    dispatch(get_performance_data(data));
  };

  var selected_products = useSelector((state) => state.Product_performance);
  if (selected_products && selected_products.comparison_table_object.length)
      var comparison_table = structuredClone(selected_products.comparison_table_object);

  var label = useSelector((state) => state.Product_performance.comparison_chart_base_label);
  var label = structuredClone(label);

  ////////////////
  var rev_comparison = useSelector((state) => state.Product_performance.rev_comparison);
  var rev_obj = {};
  if (rev_comparison) {
      rev_obj.labels = label;
      rev_obj.datasets = structuredClone(rev_comparison);
  }
  ////////////////
  var order_comparison = useSelector((state) => state.Product_performance.order_comparison);
  var order_obj = {};
  if (order_comparison) {
      order_obj.labels = label;
      order_obj.datasets = structuredClone(order_comparison);
  }
  ////////////////
  var profit_comparison = useSelector((state) => state.Product_performance.profit_comparison);
  var profit_obj = {};
  if (profit_comparison) {
      profit_obj.labels = label;
      profit_obj.datasets = structuredClone(profit_comparison);
  }
  ////////////////
  var cus_comparison = useSelector((state) => state.Product_performance.cus_comparison);
  var cus_obj = {};
  if (cus_comparison) {
      cus_obj.labels = label;
      cus_obj.datasets = structuredClone(cus_comparison);
  }

  ////////////////
  var rcus_comparison = useSelector((state) => state.Product_performance.rcus_comparison);
  var rcus_obj = {};
  if (rcus_comparison) {
      rcus_obj.labels = label;
      rcus_obj.datasets = structuredClone(rcus_comparison);
  }
  ////////////////
  var unit_comparison = useSelector((state) => state.Product_performance.unit_comparison);
  var unit_obj = {};
  if (unit_comparison) {
      unit_obj.labels = label;
      unit_obj.datasets = structuredClone(unit_comparison);
  }

  var [dr, setdr] = useState([
    new Date(moment().startOf("month")),
    new Date(moment().endOf("month")),
  ]);

  var option = {
    scales: {
      y: {
        beginAtZero: true,
        display: true,
      },
      x: {
        display: false,
      },
    },

    lineTension: 0.3,
    legend: {
      display: true,
      position: "right",
    },
  };

 
  
  var handleProductFrom = (ProductFrom) => {
    setSelectedOption(ProductFrom);
  };

  var [catagory_id, setCatagory_id] = useState("");

  var [selectedOption, setSelectedOption] = useState('specific_product');

  return (
    
    <Grid container spacing={3}>
      
      <Grid item xs={12} className="top-wrap">
        <div className="notifications">
          <h6>Product : Performance</h6>
          <div className="notify"><NavButton /></div>
        </div>
      </Grid>

      <Grid item xs={12}>
        
        <form className="date-period" style={{ marginBottom: "-15px" }} onSubmit={dateSubmit}>
          
          <DateRangePicker
            value={dr}
            onChange={setdr}
            oneTap={false}
            ranges={[
              {label: "Yesterday",value: [addDays(new Date(), -1), addDays(new Date(), -1)]},
              {label: "Today", value: [new Date(), new Date()] },
              {label: "Tomorrow",value: [addDays(new Date(), 1), addDays(new Date(), 1)]},
              {label: "Last 7 days",value: [subDays(new Date(), 6), new Date()]},
              {label: "This month",value: [subDays(new Date(), getDate(new Date()) - 1),new Date()]},
              {label: "Last month",value: [startOfMonth(subDays(new Date(), getDate(new Date()))),endOfMonth(subDays(new Date(), getDate(new Date())))]},
              {label: "Year To date",value: [startOfYear(new Date()), new Date()]},
            ]}>
          </DateRangePicker>

          <RadioGroup style={{ display: "inline-block", color: "#fff" }} onChange={(e) => { setDuration(e.target.value);}}>
            <Radio value="daily" name="onsale" /> Day
            <Radio value="weekly" name="onsale" /> Week
            <Radio value="monthly" name="onsale" /> Month
          </RadioGroup>
        
          <div>

            <RadioGroup style={{ display: "inline-block", color: "#fff" }} onChange={(e) => { handleProductFrom(e.target.value);}}>
              <Radio  checked={selectedOption === 'specific_product'} value="specific_product" name="ProductFrom" /> Specific products
              <Radio  checked={selectedOption === 'products_from_a_category'} value="products_from_a_category" name="ProductFrom" />  Products from a category
            </RadioGroup>

            {selectedOption === 'specific_product' && <Products style={{ marginBottom: '7px' }} /> }
            
            {selectedOption === 'products_from_a_category' &&  ops1 && 
              <Select className="multi" placeholder={"Select Product's from specific catagory"}
                onChange={(e) => {setCatagory_id(e.value);}}
                options={ops1}/>
            }
            <input name="Catagory_id" type="hidden" value={catagory_id}/>

          </div>

          <input name="dateRange" type={"hidden"} value={format(dr[0], "yyyy-MM-dd") + "To" + format(dr[1], "yyyy-MM-dd")}/>

          <input name="unit" type={"hidden"} value={duration} />
          <input name="ajax_call" type={"hidden"} value="1" />
          <input style={{marginLeft:"0px",marginTop:"10px"}} className="period-btn" variant="contained" color="secondary" type="submit" value="Submit"/>

        </form>

      </Grid>

      <Grid item xs={12}>

        <Card className="dash-card">
          {
            comparison_table && comparison_table.length > 0 &&
            <ThemeProvider theme={defaultMaterialTheme}>
             
              <MaterialTable
                columns={[

                  {title: "Product",field: "product_name", render: (row) => (<div style={{ background: "whitesmoke" }}>{row.product_name}</div>)},
                  
                  {title: "Cus",field: "total_cus",render: (row) => (<div style={{ background: "ghostwhite" }}>{row.total_cus}</div>)},

                  {title: "Order",field: "total_order",render: (row) => (<div style={{ background: "whitesmoke" }}>{row.total_order}</div>)},

                  {title: "offer-Order",field: "On_discount_buy",render: (row) => (<div style={{ background: "ghostwhite" }}>{row.On_discount_buy}</div>)},

                  {title: "Unit",field: "total_unit", render: (row) => ( <div style={{ background: "whitesmoke" }}>{row.total_unit}</div>)},

                  {title: "Unit/Order",field: "unit_per_order", render: (row) => ( <div style={{ background: "ghostwhite" }}>{row.unit_per_order} </div>)},
                  
                  {title: "Rev",field: "total_rev", render: (row) => (<div style={{ background: "whitesmoke" }}>{row.total_rev}</div>)},

                  {title: "Rev/Order",field: "rev_per_order", render: (row) => (<div style={{ background: "ghostwhite" }}>{row.rev_per_order}</div>)},

                  {title: "Rev/Cus",field: "rev_per_cus",render: (row) => (<div style={{ background: "whitesmoke" }}>{row.rev_per_cus}</div>)},

                  {title: "Profit",field: "total_profit", render: (row) => (<div style={{ background: "ghostwhite" }}>{row.total_profit}</div>)},

                ]}

                data={comparison_table}
                title="Sales Data :: Products"
                icons={{
                  Check: Check,
                  DetailPanel: ChevronRight,
                  Export: SaveAlt,
                  Filter: FilterList,
                  FirstPage: FirstPage,
                  LastPage: LastPage,
                  NextPage: ChevronRight,
                  PreviousPage: ChevronLeft,
                  Search: Search,
                  ResetSearch: CancelIcon,
                }}
                options={{
                  pageSize: 10, // make initial page size
                  showFirstLastPageButtons: false,
                  emptyRowsWhenPaging: false, // To avoid of having empty rows
                  pageSizeOptions: [10, 15, 25, 40, 50],
                  search: true,
                  searchFieldAlignment: "right",
                  exportButton: true,
                  exportAllData: true,
                  cellStyle: {
                    padding: "4px",
                    lineHeight: 2,
                    fontFamily: "Circular-Loom",
                    textAlign: "center",
                    borderBottom: "2px solid rgb(246, 224, 224)",
                  },
                }}
                localization={{
                  pagination: {
                    labelRowsPerPage: "",
                    showFirstLastPageButtons: false,
                  },
                }}
              />
            </ThemeProvider>
          }
        </Card>

      </Grid>


      <Grid item xs={12}>
        
        <Card className="dash-card">
        
          <Timeline className="timeline">
        
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot className="tml-title-icon">
                  <span>Total order including each product  </span>
                  <ShoppingBasketIcon />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent className="tml-chart">
                {
                  Array.isArray(order_obj.datasets) && order_obj && order_obj.labels && order_obj.datasets && 
                  <Line width={700} height={350} data={order_obj} options={option} />
                }
              </TimelineContent>
            </TimelineItem>

            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot className="tml-title-icon">
                  <span>Total unit sold from each product  </span>
                  <LayersRoundedIcon />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent className="tml-chart">
                {
                  Array.isArray(unit_obj.datasets) && unit_obj && unit_obj.labels && unit_obj.datasets && 
                  <Line width={700} height={350} data={unit_obj} options={option} />
                }
              </TimelineContent>
            </TimelineItem>

            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot className="tml-title-icon">
                  <span>Total revenue generated from each product  </span>
                  <AttachMoneyIcon />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent className="tml-chart">
                {
                  Array.isArray(rev_obj.datasets) && rev_obj && rev_obj.labels && rev_obj.datasets &&  
                  <Line width={700} height={350} data={rev_obj} options={option} />
                }
              </TimelineContent>
            </TimelineItem>

            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot className="tml-title-icon">
                  <span>Total Customer from each product  </span>
                  <PeopleAltIcon />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent className="tml-chart">
                {
                  Array.isArray(cus_obj.datasets) && cus_obj && cus_obj.labels && cus_obj.datasets && 
                  <Line width={700} height={350} data={cus_obj} options={option} />
                }
              </TimelineContent>
            </TimelineItem>

            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot className="tml-title-icon">
                  <span>Total Repeat Customer from each product  </span>
                  <RepeatIcon />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent className="tml-chart">
                {
                  Array.isArray(rcus_obj.datasets) && rcus_obj && rcus_obj.labels && rcus_obj.datasets && 
                  <Line width={700} height={350} data={rcus_obj} options={option} />
                }
              </TimelineContent>
            </TimelineItem>
          </Timeline>
        </Card>

      </Grid>

    </Grid>

  );

}

export default ProductPerformance;
