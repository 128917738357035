import { ReactSession } from "react-client-session";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Grid, Input } from "@mantine/core";
import { useSelector, useDispatch } from "react-redux";
import Multiselect from "multiselect-react-dropdown";
//import { get_product_and_catagory_and_sku_data } from "../../../features/product/ProductListAndSegment";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";

function ProductSKU() {
  
  // var dispatch = useDispatch();
  var [sku, setSku] = useState("");

  // var is_dispatched1 = () => {
  //   ReactSession.get("get_product_and_catagory_and_sku_data");
  //   if (ReactSession.get("get_product_and_catagory_and_sku_data")) {
  //     return true;
  //   } else {
  //     ReactSession.set("get_product_and_catagory_and_sku_data", "1");
  //     return false;
  //   }
  // };

  // if (!is_dispatched1()) { dispatch(get_product_and_catagory_and_sku_data({ ajax_call: 2 })); }

  // var skus = useSelector((state) => state.product_List_And_Segments.product_sku);


    
  var handleProductFrom = (ProductFrom) => {
    setSelectedOption(ProductFrom);
  };

  var [catagory_id, setCatagory_id] = useState("");

  var [selectedOption, setSelectedOption] = useState('specific_product');

  return (
    
    <div className="input-filters" style={{ display: "block" }}>
    
      {/* <Multiselect
        placeholder="Select-SKU"
        onRemove={(e) => {setSku(JSON.stringify(e));}}
        onSelect={(e) => {setSku(JSON.stringify(e));}}
        options={skus}
        selectedValues={[]}
        showCheckbox
      /> */}

      {/* {skus && skus.length > 0 ? (
        <Multiselect
          placeholder="Select-SKU"
          onRemove={(e) => {setSku(JSON.stringify(e));}}
          onSelect={(e) => {setSku(JSON.stringify(e));}}
          options={skus}
          selectedValues={[]}
          showCheckbox
        />
      ) : (
        <p>No SKUs available.</p>
      )} */}

      {/* <Input name="sku" type={"hidden"} value={sku} /> */}

      <RadioGroup style={{ display: "inline-block", color: "#fff" }} onChange={(e) => { handleProductFrom(e.target.value);}}>
              <Radio  checked={selectedOption === 'specific_product'} value="specific_product" name="ProductFrom" /> Specific products
              <Radio  checked={selectedOption === 'products_from_a_category'} value="products_from_a_category" name="ProductFrom" />  Products from a category
            </RadioGroup>

            {selectedOption === 'specific_product' && <>BLOCK 1</> }
            
            {selectedOption === 'products_from_a_category' && <>BLOCK 2</>
             
            }

    </div>
  );
}

export default ProductSKU;
