import { ReactSession } from "react-client-session";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { get_segment_join_drop_chart_object } from "../../features/cus/CusSegmentReport";
import { Line } from "react-chartjs-2";
import Grid from "@mui/material/Grid";
import NavButton from "../../pages/NavButton";

function CusSegmentTrack() {

  var dispatch = useDispatch();

  var segmentStyle = {
    "backgroundColor": "#fff",
    "padding": "1rem",
    "borderRadius": "4px"
  }


  useEffect(() => {
    
    var is_dispatched = () => {
      
      ReactSession.get("get_segment_join_drop_chart_object");
      if(ReactSession.get("get_segment_report")) {
        return true;
      } else {
        ReactSession.set("get_segment_join_drop_chart_object", "1");
        return false;
      }
    }

    if (!(is_dispatched())) {
      dispatch(get_segment_join_drop_chart_object());
    }

  }, []);


  var segment_join_drop_chart_object = useSelector((state) => state.CustomerSegmentReport.segment_join_drop_chart_object);

  return (

    <Grid container style={{maxWidth: "1300px", margin: "0 auto"}} >

      <Grid item xs={12} className="top-wrap" style={{marginBottom:"2%"}}>
        <div className="notifications">
          <h6> Customer :: Segment Tracking </h6>
          <div className="notify">
            <NavButton />
          </div>
        </div>
      </Grid>

      {/* {segment_join_drop_chart_object && segment_join_drop_chart_object.map(segment => (
        
        <>
        
          <h3>{segment.segment_name}</h3>

          <div key={segment.segment_name} style={{display:'flex'}}>
          
            <Grid item xl={6} lg={6} md={6}  sm={12} xs={12} style={{ margin: "2%", height:"400px", width:"auto" }}>
              
                <Line 
                  data={{
                    labels: segment.join_label.split(','),
                    datasets: [{
                      label: 'Joining Data',
                      data: segment.join_data.split(','),
                      backgroundColor: 'rgba(75, 192, 192, 0.2)',
                      borderColor: 'rgba(75, 192, 192, 1)',
                      borderWidth: 2,
                    }],
                  }}
                  options={{ maintainAspectRatio: false, height: 400 }}
                />
              
            </Grid>

            <Grid item xl={6} lg={6} md={6}  sm={12} xs={12} style={{ margin: "2%",height:"400px",width:"auto"  }}>
              
                <Line
                  data={{
                    labels: segment.drop_label.split(','),
                    datasets: [{
                      label: 'Drop Data',
                      data: segment.drop_data.split(','),
                      backgroundColor: 'rgba(192, 75, 75, 0.2)',
                      borderColor: 'rgba(192, 75, 75, 1)',
                      borderWidth: 2,
                    }],
                  }}
                  options={{ maintainAspectRatio: false, height: 400 }}
                />
              

            </Grid>

          </div>
        
        </>

      ))} */}

      {segment_join_drop_chart_object && segment_join_drop_chart_object.map(segment => (
          
        <div style={{display:"contents"}}>
          
          <Grid container spacing={1} style={{display:"block"}}>
            
            <h5 style={{background: "#EDF2F8", padding: ".4rem 1rem", "borderRadius":'3px', "marginBottom":"10px","width":"fit-content"}}>
              {segment.segment_name}
            </h5>
          
            <div style={{display:"flex"}}>

              <Grid item xl={6} lg={6} xs={12} sm={5} md={5} style={{...segmentStyle,"height":"352px","marginRight":"1%"}} >
                
                <Line style={{height:"350px"}}
                  data={{
                    labels: segment.join_label.split(','),
                    datasets: [{
                      label: 'Joining Data',
                      data: segment.join_data.split(','),
                      backgroundColor: 'rgba(75, 192, 192, 0.2)',
                      borderColor: 'rgba(75, 192, 192, 1)',
                      borderWidth: 2,
                    }],
                  }}
                />
              </Grid>

              <Grid item xl={6} lg={6} xs={12} sm={5} md={5} style={{...segmentStyle,height:"352px"}} >
                
                <Line style={{height:"350px"}}
                  data={{
                    labels: segment.drop_label.split(','),
                    datasets: [{
                      label: 'Drop Data',
                      data: segment.drop_data.split(','),
                      backgroundColor: 'rgba(192, 75, 75, 0.2)',
                      borderColor: 'rgba(192, 75, 75, 1)',
                      borderWidth: 2,
                    }],
                  }}
                />
                
              </Grid>

            </div>
            

          </Grid>

        </div>

      ))}

    </Grid>
  
  )}

export default CusSegmentTrack