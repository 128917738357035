import React, { useState, useEffect } from "react";

import Grid from "@mui/material/Grid";
import { useSelector, useDispatch } from "react-redux";
import DashRecentSales from "../components/Dash/DashRecentSales";
import DashTops from "../components/Dash/DashTops";
import StorageIcon from "@material-ui/icons/Storage";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import WidgetsIcon from "@material-ui/icons/Widgets";
import UnarchiveIcon from "@material-ui/icons/Unarchive";
import NotificationsIcon from "@material-ui/icons/Notifications";
import SettingsIcon from "@material-ui/icons/Settings";
import "rsuite/dist/rsuite.css";
import { Card } from "react-bootstrap";
import NavButton from "./NavButton";

//When the user scrolls down 20px from the top of the document Add/Remove class
onscroll = () => {
  const selector = document.querySelector(".notifications");
  (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20)
  ? selector.classList.add("topper")
  : selector.classList.remove("topper");
};


onscroll = () => {
  const notificationElement = document.querySelector(".notifications");
  if (notificationElement) {
    notificationElement.classList.toggle("topper", window.scrollY > 20);
  }
};

function Dashboard() {

  var dispatch = useDispatch();

  console.log(useSelector((state) => state.dashTops.status));

  var cus_note = [];
  var order_note = [];

  return (
    
    <>
    
      <Grid container spacing={3}>
    
        <Grid item xs={12} className="top-wrap">
          <div className="notifications">
            <h6>Dashboard</h6>
            <div className="notify">
              <SettingsIcon />
              <NotificationsIcon />
              <NavButton />
            </div>
          </div>
        </Grid>

        <Grid item xs={12}>

          <Grid container spacing={3} sx={{ padding: "0 !important" }}>
            
            <Grid item xl={3} lg={6} md={6} sm={6} xs={12}>
              <Card className="dash-card key-note">
                <div>
                  <h6> Total sales </h6>
                  <h4>$55,000.00</h4>
                  <p>
                    <span>+55%</span>Last month
                  </p>
                </div>
                <StorageIcon />
              </Card>
            </Grid>

            <Grid item xl={3} lg={6} md={6} sm={6} xs={12}>
              <Card className="dash-card key-note">
                <div>
                  <h6> Average order value </h6>
                  <h4>$55,000.00</h4>
                  <p>
                    <span>+55%</span>Last month
                  </p>
                </div>
                <ShoppingBasketIcon
                  style={{
                    background: "linear-gradient(310deg,#f5365c,#f56036)",
                  }}
                />
              </Card>
            </Grid>

            <Grid item xl={3} lg={6} md={6} sm={6} xs={12}>
              <Card className="dash-card key-note">
                <div>
                  <h6> Total number of orders </h6>
                  <h4>433</h4>
                  <p>
                    <span>+55%</span>Last month
                  </p>
                </div>
                <UnarchiveIcon
                  style={{
                    background: "linear-gradient(310deg,#2dce89,#2dcecc)",
                  }}
                />
              </Card>
            </Grid>

            <Grid item xl={3} lg={6} md={6} sm={6} xs={12}>
              <Card className="dash-card key-note">
                <div>
                  <h6> Profit </h6>
                  <h4>$19,000.00</h4>
                  <p>
                    <span>+55%</span>Last month
                  </p>
                </div>
                <WidgetsIcon
                  style={{
                    background: "linear-gradient(310deg,#fb6340,#fbb140)",
                  }}
                />
              </Card>
            </Grid>

          </Grid>

        </Grid>

       
        <DashTops />
       
      </Grid>

      {/* <DashEmail/> */}
    
    </>
  
  );

}

export default Dashboard;
