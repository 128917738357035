import { ReactSession } from "react-client-session";
import React, { useEffect, useState } from "react";

import { format } from "date-fns";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import startOfYear from "date-fns/startOfYear";
import { addDays, subDays, getDate } from "date-fns";
import { useSelector, useDispatch } from "react-redux";
import { DateRangePicker } from "rsuite";
import Grid from "@mui/material/Grid";
import moment from "moment";
import { get_cusLocCT_data } from "../../features/cus/CusLocCT";
import { get_trf_data } from "../../features/cus/Cus_new_repeat_total_Chart";

import CusCityStateChart from "./CusCityStateChart";
import CusCityStateTable from "./CusCityStateTable";
import CusFromThisMonth from "./CusFromThisMonth";
import CusGroupByFirstMonth from "./CusGroupByFirstMonth";
import { Timeline } from "rsuite";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

import "rsuite/dist/rsuite.css";

import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend,} from "chart.js";

import { Line } from "react-chartjs-2";
import { Card } from "react-bootstrap";
import NavButton from "../../pages/NavButton";

ChartJS.register( CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

function CusReport() {

  var dispatch2 = useDispatch();
  var dispatch3 = useDispatch();
  var dispatch6 = useDispatch();

  useEffect(() => {

    var is_dispatched3 = (dispatch_function) => {
      ReactSession.get("get_trf_data");
      if (ReactSession.get("get_trf_data")) {
          return true;
      } else {
          ReactSession.set("get_trf_data", "1");
          return false;
        }
    }

    if (!(is_dispatched3('get_trf_data'))) {
        dispatch2(get_trf_data({ ajax_seg: 2 }));
    }
  
  },[]);

  
  var trf = useSelector((state) => state.cusTRF);

  var new_side_note_Array    = trf.f_s_note ? trf.f_s_note.split("shop") : [];
  var repeat_side_note_Array = trf.r_s_note ? trf.r_s_note.split("shop") : [];
  var total_side_note_Array  = trf.total_s_note ? trf.total_s_note.split("shop") : [];


  var totcus_labels = trf.total_label ? trf.total_label.replace(/\"/g, "").split(",") : [];
  var totcus_data   = trf.total_data ? trf.total_data.replace(/\"/g, "").split(",") : [];
  
  var totcus_object = {
      labels: totcus_labels,
      datasets: [{
          label: 'Total-Customer',
          backgroundColor: 'rgba(0, 198, 198, 0.3)',
				  borderColor: 'rgba(0, 198, 198, 0.3)',
				  fill: true,
          pointRadius: 4,
          borderWidth:2,
          data: totcus_data
      }]
  }

  var nrcus_labels  = trf.f_r_label ? trf.f_r_label.replace(/\"/g, "").split(","):[];
  var new_data      = trf.ftime_data ? trf.ftime_data.replace(/\"/g, "").split(","):[];
  var repeat_data   = trf.ret_data ? trf.ret_data.replace(/\"/g, "").split(",") : [];
  
  var nrcus_object = {
      labels: nrcus_labels,
      datasets: [{
        label: 'New-Customer',
        backgroundColor: 'rgba(211, 211, 211, 0.5)',
				borderColor: 'rgba(211, 211, 211, 0.3)',
				fill: true,
        pointRadius: 4,
        borderWidth:2,
        data: new_data,
      },
      {
        label: 'Repeat-Customer',
        background: "rgba(231, 76, 60, 0.7)",
        borderColor: "rgba(231, 76, 60, 1)",
				fill: true,
        pointRadius: 4,
        borderWidth:2,
        data: repeat_data,
      }]
  }

  const options = {
    scales: {
      x: {
        grid: {
          display: false, // Set this to false to hide the x-axis grid
        },
      },
    },
  };


  var [daterange2, setdrange2] = useState([ new Date(moment().startOf("month")), new Date(moment().endOf("month"))]);

  var [duration2, setduration2] = useState();

  var dateSubmit2 = (e) => {
    dispatch3(
      get_trf_data({
        from: format(daterange2[0], "yyyy-MM-dd"),
        to: format(daterange2[1], "yyyy-MM-dd"),
        unit: duration2,
        ajax_seg: 1,
      })
    );
  };

  var [daterange, setdrange] = useState([ new Date(moment().startOf("month")), new Date(moment().endOf("month"))]);
  var [daterange1, setdrange1] = useState([ startOfMonth(subDays(new Date(), getDate(new Date()))),endOfMonth(subDays(new Date(), getDate(new Date())))]);

  var [duration, setduration] = useState();
  var [cusType, setcusType] = useState();
  
  var dateSubmit = (e) => {
    dispatch6(
      get_cusLocCT_data({
        from: format(daterange[0], "yyyy-MM-dd"),
        to: format(daterange[1], "yyyy-MM-dd"),
        from1: format(daterange1[0], "yyyy-MM-dd"),
        to1: format(daterange1[1], "yyyy-MM-dd"),
        unit: duration,
        type: cusType,
        ajax_seg: 1,
      })
    );
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} className="top-wrap">
          <div className="notifications">
            <h6>Customer Reports</h6>
            <div className="notify">
              <NavButton />
            </div>
          </div>
        </Grid>

        <Grid item xs={12}>
        
          <div className="date-period">
            <DateRangePicker
              // label="Timeline"
              value={daterange2}
              onChange={setdrange2}
              oneTap={false}
              ranges={[
                {label: "Yesterday",value: [addDays(new Date(), -1), addDays(new Date(), -1)]},
                {label: "Today", value: [new Date(), new Date()] },
                {label: "Tomorrow",value: [addDays(new Date(), 1), addDays(new Date(), 1)]},
                {label: "Last 7 days", value: [subDays(new Date(), 6), new Date()]},
                {label: "This month",value: [subDays(new Date(), getDate(new Date()) - 1),new Date(),]},
                {abel: "Last month",value: [startOfMonth(subDays(new Date(), getDate(new Date()))),endOfMonth(subDays(new Date(), getDate(new Date())))]},
                {label: "Year To date",value: [startOfYear(new Date()), new Date()]},
              ]}>
            </DateRangePicker>

            <RadioGroup style={{ display:"inline-block",fontSize:"13px",color:"white",fontWeight:"500"}}onChange={(e) => { setduration2(e.target.value);}}>
              <Radio checked={duration === "daily"} value="daily" name="duration" /> Day
              <Radio checked={duration === "weekly"} value="weekly" name="duration" /> Week
              <Radio checked={duration === "monthly"} value="monthly" name="duration" /> Month
            </RadioGroup>
            <button className="period-btn" variant="contained" color="secondary"  onClick={dateSubmit2} > Submit </button>
          </div>

        </Grid>

        <Grid item xl={6} xs={12}>
          
          <Card className="dash-card">
          
            <h6>Total Customer</h6>
              {totcus_object && <Line data={totcus_object} options={options} />}
            <br />
            
            {/* <Timeline style={{ color: "cornflowerblue", padding: "10px" }}>
              
              {total_side_note_Array[0] && <Timeline.Item> Total :  {total_side_note_Array[0]}</Timeline.Item> }
              {total_side_note_Array[1] && <Timeline.Item> Min :    {total_side_note_Array[1]}</Timeline.Item> }
              {total_side_note_Array[2] && <Timeline.Item> Max :    {total_side_note_Array[2]} </Timeline.Item>}
              {total_side_note_Array[3] && <Timeline.Item> AVG :    {total_side_note_Array[3]} </Timeline.Item>}
              
              {total_side_note_Array[4] && <Timeline.Item> Total Point : {total_side_note_Array[4]} </Timeline.Item>}
              {total_side_note_Array[5] && <Timeline.Item> Point Bellow AVG : {total_side_note_Array[5]}  </Timeline.Item>}
              {total_side_note_Array[6] && <Timeline.Item> Point Above AVG :{total_side_note_Array[6]} </Timeline.Item>}
              {total_side_note_Array[7] && <Timeline.Item> Change :{total_side_note_Array[7]}</Timeline.Item>}
              {total_side_note_Array[8] && <Timeline.Item> AVG Change : {total_side_note_Array[8]}  </Timeline.Item>}

            </Timeline> */}

            <div style={{background: "whitesmoke",padding: "20px", textAlign:"center"}}>
              <p>
                <strong> {total_side_note_Array[0] && <> Total :  {total_side_note_Array[0]} </>}          </strong>
                <strong> {total_side_note_Array[1] && <> Min :    {total_side_note_Array[1]} </>}           </strong>
                <strong> {total_side_note_Array[2] && <> Max :    {total_side_note_Array[2]} </>}   </strong>
                <strong> {total_side_note_Array[3] && <> AVG :    {total_side_note_Array[3]} </>}   </strong>
              </p>

              <p>
                <strong> {total_side_note_Array[4]  &&  <> Total Point : {total_side_note_Array[4]}       </>} </strong>
                <strong> {total_side_note_Array[5]  &&  <> Point Bellow AVG : {total_side_note_Array[5]}  </>} </strong>
                <strong>  {total_side_note_Array[6] &&  <> Point Above AVG :{total_side_note_Array[6]}    </>} </strong>
              </p>

              <p>
                <strong> {total_side_note_Array[7] && <> Change :{total_side_note_Array[7]} </>}        </strong>
                <strong> {total_side_note_Array[8] && <> AVG Change : {total_side_note_Array[8]} </>}   </strong>
              </p>
           
            </div>
            
          </Card>

        </Grid>



        <Grid item xl={6} xs={12}>
          <Card className="dash-card">
            <h6>Repeat & New Customer</h6>
            {nrcus_object && <Line data={nrcus_object} options={options} /> }
            <Grid container spacing={2} style={{ padding: 0 }}>
              <Grid item md={6}>
                {/* <Timeline>
                  {repeat_side_note_Array[0] && <Timeline.Item> Total : {repeat_side_note_Array[0]} </Timeline.Item> }
                  {repeat_side_note_Array[1] && <Timeline.Item> Min : {repeat_side_note_Array[1]} </Timeline.Item>}
                  {repeat_side_note_Array[2] && <Timeline.Item> Max :{repeat_side_note_Array[2]} </Timeline.Item>}
                  {repeat_side_note_Array[3] && <Timeline.Item> AVG :{repeat_side_note_Array[3]} </Timeline.Item>}
                  {repeat_side_note_Array[4] && <Timeline.Item> Total Point : {repeat_side_note_Array[4]} </Timeline.Item>}
                  {repeat_side_note_Array[5] && <Timeline.Item> Point Bellow AVG : {repeat_side_note_Array[5]} </Timeline.Item>}
                  {repeat_side_note_Array[6] && <Timeline.Item> Point Above AVG :{repeat_side_note_Array[6]} </Timeline.Item>}
                  {repeat_side_note_Array[7] && <Timeline.Item> Change :{repeat_side_note_Array[7]} </Timeline.Item>}
                  {repeat_side_note_Array[8] && <Timeline.Item> AVG Change : {repeat_side_note_Array[8]} </Timeline.Item>}
                </Timeline> */}
                <div style={{background: "whitesmoke",padding: "20px", textAlign:"center"}}>
                  <p>
                    <strong> {repeat_side_note_Array[0] && <> Total :  {repeat_side_note_Array[0]} </>}          </strong>
                    <strong> {repeat_side_note_Array[1] && <> Min :    {repeat_side_note_Array[1]} </>}           </strong>
                    <strong> {repeat_side_note_Array[2] && <> Max :    {repeat_side_note_Array[2]} </>}   </strong>
                    <strong> {repeat_side_note_Array[3] && <> AVG :    {repeat_side_note_Array[3]} </>}   </strong>
                  </p>

                  <p>
                    <strong> {repeat_side_note_Array[4]  &&  <> Total Point : {repeat_side_note_Array[4]}       </>} </strong>
                    <strong> {repeat_side_note_Array[5]  &&  <> Point Bellow AVG : {repeat_side_note_Array[5]}  </>} </strong>
                    <strong> {repeat_side_note_Array[6] &&  <> Point Above AVG :{repeat_side_note_Array[6]}    </>} </strong>
                  </p>

                  <p>
                    <strong> {repeat_side_note_Array[7] && <> Change :{repeat_side_note_Array[7]} </>}        </strong>
                    <strong> {repeat_side_note_Array[8] && <> AVG Change : {repeat_side_note_Array[8]} </>}   </strong>
                  </p>
              
                </div>
              </Grid>

              <Grid item md={6}>

                {/* <Timeline>

                  {new_side_note_Array[0] && <Timeline.Item> Total : {new_side_note_Array[0]} </Timeline.Item>}
                  {new_side_note_Array[1] &&   <Timeline.Item> Min : {new_side_note_Array[1]}   </Timeline.Item>}
                  {new_side_note_Array[2] &&   <Timeline.Item> Max : {new_side_note_Array[2]}   </Timeline.Item>}
                  {new_side_note_Array[3] &&   <Timeline.Item> AVG : {new_side_note_Array[3]}   </Timeline.Item>}
                  {new_side_note_Array[4] &&   <Timeline.Item> Total Point : {new_side_note_Array[4]} </Timeline.Item>}
                  {new_side_note_Array[5] &&   <Timeline.Item> Point Bellow AVG : {new_side_note_Array[5]}  </Timeline.Item>}
                  {new_side_note_Array[6] &&   <Timeline.Item> Point Above AVG :{new_side_note_Array[6]} </Timeline.Item>}
                  {new_side_note_Array[7] &&   <Timeline.Item> Change : {new_side_note_Array[7]} </Timeline.Item>}
                  {new_side_note_Array[8] &&   <Timeline.Item> AVG Change : {new_side_note_Array[8]}  </Timeline.Item>}

                </Timeline> */}
                <div style={{background: "whitesmoke",padding: "20px", textAlign:"center"}}>
                  <p>
                    <strong> {new_side_note_Array[0] && <> Total :  {new_side_note_Array[0]} </>}          </strong>
                    <strong> {new_side_note_Array[1] && <> Min :    {new_side_note_Array[1]} </>}           </strong>
                    <strong> {new_side_note_Array[2] && <> Max :    {new_side_note_Array[2]} </>}   </strong>
                    <strong> {new_side_note_Array[3] && <> AVG :    {new_side_note_Array[3]} </>}   </strong>
                  </p>

                  <p>
                    <strong> {new_side_note_Array[4]  &&  <> Total Point : {new_side_note_Array[4]}       </>} </strong>
                    <strong> {new_side_note_Array[5]  &&  <> Point Bellow AVG : {new_side_note_Array[5]}  </>} </strong>
                    <strong> {new_side_note_Array[6] &&  <> Point Above AVG :{new_side_note_Array[6]}    </>} </strong>
                  </p>

                  <p>
                    <strong> {new_side_note_Array[7] && <> Change :{new_side_note_Array[7]} </>}        </strong>
                    <strong> {new_side_note_Array[8] && <> AVG Change : {new_side_note_Array[8]} </>}   </strong>
                  </p>
              
                </div>

              </Grid>
            </Grid>
          </Card>
        </Grid>

        <CusFromThisMonth />

        <Grid item xl={12} lg={12} md={12}  sm={12} xs={12}>
          <CusGroupByFirstMonth />
        </Grid> 
       

        <Grid item xs={12}>
          
          <br />
          
          <h6>Location Based Data</h6>
          
          <br />
          
          <div className="date-period" style={{ marginBottom: "-15px" }}>
          
            <DateRangePicker
              value={daterange}
              onChange={setdrange}
              oneTap={false}
              ranges={[
                {label: "Yesterday",value: [addDays(new Date(), -1), addDays(new Date(), -1)]},
                {label: "Today", value: [new Date(), new Date()] },
                {label: "Tomorrow",value: [addDays(new Date(), 1), addDays(new Date(), 1)]},
                {label: "Last 7 days", value: [subDays(new Date(), 6), new Date()]},
                {label: "This month",value: [subDays(new Date(), getDate(new Date()) - 1),new Date(),]},
                {abel: "Last month",value: [startOfMonth(subDays(new Date(), getDate(new Date()))),endOfMonth(subDays(new Date(), getDate(new Date())))]},
                {label: "Year To date",value: [startOfYear(new Date()), new Date()]},
              ]}>
            </DateRangePicker>

            <DateRangePicker
              value={daterange1}
              onChange={setdrange1}
              oneTap={false}
              ranges={[
                {label: "Yesterday",value: [addDays(new Date(), -1), addDays(new Date(), -1)]},
                {label: "Today", value: [new Date(), new Date()] },
                {label: "Tomorrow",value: [addDays(new Date(), 1), addDays(new Date(), 1)]},
                {label: "Last 7 days", value: [subDays(new Date(), 6), new Date()]},
                {label: "This month",value: [subDays(new Date(), getDate(new Date()) - 1),new Date(),]},
                {abel: "Last month",value: [startOfMonth(subDays(new Date(), getDate(new Date()))),endOfMonth(subDays(new Date(), getDate(new Date())))]},
                {label: "Year To date",value: [startOfYear(new Date()), new Date()]},
              ]}>

            </DateRangePicker>

            <RadioGroup onChange={(e) => {setduration(e.target.value);}} style={{display: "inline-block",fontSize: "13px",fontWeight: "500",}}>
              <Radio type="radio" value="daily" name="gender" /> Daily
              <Radio type="radio" value="weekly" name="gender" /> Weekly
              <Radio type="radio" value="monthly" name="gender" /> Monthly
            </RadioGroup>

            <RadioGroup onChange={(e) => {setcusType(e.target.value);}}style={{display: "inline-block",fontSize: "13px",fontWeight: "500",}}>
              <Radio type="radio" value="both" name="cus_type" /> Both
              <Radio type="radio" value="new" name="cus_type" />New
              <Radio type="radio" value="returning" name="cus_type" /> Returning
            </RadioGroup>
            
            <button className="period-btn" variant="contained" color="secondary" onClick={dateSubmit} > Submit </button>

          </div>

        </Grid>

        <CusCityStateTable />

        <Grid item xs={12}>
          <CusCityStateChart />
        </Grid>
      </Grid>
    </>
  );
}

export default CusReport;
