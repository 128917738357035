import React from "react";

function TransecID() {
  return (
    <div className="input-filters">
      <strong> Transection-ID :</strong>
      <input className="multi" defaultValue="" type="text" name="transid" />
    </div>
  );
}

export default TransecID;
